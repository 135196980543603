<template>
  <div>
    <el-dialog title=""
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :modal='false'
               width="480rem"
               @opened="focusInput"
               :before-close="handleClose">
      <div class="head_img">
        <div class="text">你的高考目标</div>
        <div class="text1">我们好为你智能分析各考点的达标情况</div>
      </div>
      <el-form :model="form"
               label-width="130rem">
        <el-row>

          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id ==7)">
            <el-form-item label="语文：">
              <el-input-number v-model="subjectListData[0].score"
                               ref="7"
                               @change="handleChange(subjectListData[0])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id ==12)">
            <el-form-item label="数学：">
              <el-input-number v-model="subjectListData[1].score"
                               ref="12"
                               @change="handleChange(subjectListData[1])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id ==11)">
            <el-form-item label="英语：">
              <el-input-number v-model="subjectListData[2].score"
                               ref="11"
                               @change="handleChange(subjectListData[2])"
                               label="描述文字"></el-input-number>

            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 4)">
            <el-form-item label="物理：">
              <el-input-number v-model="subjectListData[3].score"
                               ref="4"
                               @change="handleChange(subjectListData[3])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 9)">
            <el-form-item label="化学：">
              <el-input-number v-model="subjectListData[4].score"
                               ref="9"
                               @change="handleChange(subjectListData[4])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 10)">
            <el-form-item label="生物学：">
              <el-input-number v-model="subjectListData[5].score"
                               ref="10"
                               @change="handleChange(subjectListData[5])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 6)">
            <el-form-item label="历史：">
              <el-input-number v-model="subjectListData[6].score"
                               ref="6"
                               @change="handleChange(subjectListData[6])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id ==5)">
            <el-form-item label="思想政治：">
              <el-input-number v-model="subjectListData[7].score"
                               ref="5"
                               @change="handleChange(subjectListData[7])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24"
                  v-if="subjectList && subjectList.some(item => item.subject_id == 3)">
            <el-form-item label="地理：">
              <el-input-number v-model="subjectListData[8].score"
                               ref="3"
                               @change="handleChange(subjectListData[8])"
                               label="描述文字"></el-input-number>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div class="btn_group">
        <div class="btn"
             @click="close">
          不再提示
        </div>
        <div class="btn1"
             @click="save">
          确 认
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getStudentSubject } from '@/api/subject.js'
export default {
  data () {
    return {
      dialogVisible: false,
      subjectList: [],
      form: {
      },
      subjectListData: [

        {
          "subject_id": 7,
          "subject_name": "语文",
          'score': 130,
        },
        {
          "subject_id": 12,
          "subject_name": "数学",
          'score': 130,
        },
        {
          "subject_id": 11,
          "subject_name": "英语",
          'score': 130,
        },
        {
          "subject_id": 4,
          "subject_name": "物理",
          'score': 90,
        },
        {
          "subject_id": 9,
          "subject_name": "化学",
          'score': 90,
        },
        {
          "subject_id": 10,
          "subject_name": "生物学",
          'score': 90,
        },
        {
          "subject_id": 6,
          "subject_name": "历史",
          'score': 90,
        },
        {
          "subject_id": 5,
          "subject_name": "思想政治",
          'score': 90,
        },
        {
          "subject_id": 3,
          "subject_name": "地理",
          'score': 90,
        },
      ],
      scoreData: [],
      isValid: true
    }
  },
  async created () {
    const { data } = await getStudentSubject()
    let totalScore = 0; // 定义变量用于累加分数
    let subjectListData = [];
    subjectListData = data.list.filter(item => item.subject_id !== 13).map(item => {
      totalScore += item.score;

      if ((item.subject_id === 7 || item.subject_id === 12 || item.subject_id === 11) && item.score == 0) {
        item.score = 130; // 设置为130
      } else if (item.score == 0) {
        item.score = 90; // 其他设为90
      }

      return {
        subject_id: item.subject_id,
        score: item.score,
      }
    });
    this.$EventBus.emit('totalScoreEdit', totalScore)
    window.localStorage.setItem('subjectListData', JSON.stringify(subjectListData))

    this.subjectList = JSON.parse(window.localStorage.getItem('subjectListData'))


    this.subjectListData.map(item => {
      this.subjectList.map(item2 => {
        if ((item2.subject_id == item.subject_id) && item.score !== 0) {
          item.score = item2.score
        }
      })
    })

  },
  methods: {
    focusInput () {
      this.$nextTick(() => {
        this.$refs[window.localStorage.getItem('physical_subjectId')].focus();
      });
    },
    close () {
      this.dialogVisible = false
      window.localStorage.setItem('score_dialog', false)
    },
    handleClose () {
      this.dialogVisible = false
      // this.$refs.formRef.resetFields()
    },
    handleChange (val) {

      // console.log('val', val);
      let temp = val;
      this.isValid = true;

      if (val.subject_id == 7 || val.subject_id == 12 || val.subject_id == 11) {
        if (temp.score < 37) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：37分`);

          this.isValid = false;  // 设置为无效
        } else if (temp.score > 150) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：150分`);

          this.isValid = false;  // 设置为无效
        }
      } else {

        if (temp.score < 25) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：25分`);

          this.isValid = false;  // 设置为无效
        } else if (temp.score > 100) {

          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：100分`);


          this.isValid = false;  // 设置为无效

        }
      }
      return this.isValid; // 返回最终验证状态

    },

    subvalidate (val) {
      this.isValid = true;
      if (val.subject_id == 7 || val.subject_id == 12 || val.subject_id == 11) {
        if (val.score < 37) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：37分`);
          this.isValid = false;  // 设置为无效
        } else if (val.score > 150) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：150分`);
          this.isValid = false;  // 设置为无效
        }
      } else {

        if (val.score < 25) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最底：25分`);
          this.isValid = false;  // 设置为无效
        } else if (val.score > 100) {
          this.$message.error(`${this.subjectListData.find(item => item.subject_id == val.subject_id).subject_name}目标分数最高：100分`);
          this.isValid = false;  // 设置为无效
        }
      }

      return this.isValid; // 返回最终验证状态
    },
    async save () {


      this.subjectList.map(item2 => {
        this.subjectListData.map(item => {
          if ((item2.subject_id == item.subject_id)) {
            item2.score = item.score
          }
        })
      })
      let validationResults = [];

      validationResults = this.subjectList.map(item => {
        return new Promise(resolve => {
          setTimeout(() => {

            resolve(this.subvalidate(item)); // Resolve promise with the result of subvalidate
          }, 50);
        });
      });

      // 等待所有的 Promise 完成
      Promise.all(validationResults).then(results => {
        const isTrue = results.some(result => result == false)
        if (isTrue == true) {
          return; // 如果不可以继续，直接返回，不发送请求
        }
        let data = { "subject_score": this.subjectList }

        this.$http({
          url: '/api/v1/user/set_user_hope_score',
          method: 'post',
          data: data,
        }).then(res => {
          let totalScore = 0; // 定义变量用于累加分数
          let subjectListData = [];
          subjectListData = this.subjectList.filter(item => item.subject_id !== 13).map(item => {
            totalScore += item.score;

            if ((item.subject_id === 7 || item.subject_id === 12 || item.subject_id === 11) && item.score == 0) {
              item.score = 130; // 设置为130
            } else if (item.score == 0) {
              item.score = 90; // 其他设为90
            }

            return {
              subject_id: item.subject_id,
              score: item.score,
            }
          });
          this.$EventBus.emit('totalScoreEdit', totalScore)
          window.localStorage.setItem('subjectListData', JSON.stringify(subjectListData))
          window.localStorage.setItem('totalScore', totalScore)
          subjectListData.map(item => {
            if (JSON.parse(window.localStorage.getItem('physical_subjectId'))&&item.subject_id == JSON.parse(window.localStorage.getItem('physical_subjectId')).subject_id) {
              this.$parent.paper_duration = item.score
              let physical_subjectId = JSON.parse(window.localStorage.getItem('physical_subjectId'))
              physical_subjectId.score = item.score
              window.localStorage.setItem('physical_subjectId', JSON.stringify(physical_subjectId))
            }
          })

          this.$message.success('目标分数设置成功！')
          this.handleClose()
        })

      });


    },

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0px;
}
.head_img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32rem;
  margin-bottom: 32rem;
  .text {
    color: var(--light-333333, var(--neutral-color-333333, #333));
    text-align: center;

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 150% */
  }
  .text1 {
    color: var(--neutral-color-666666, #666);
    text-align: center;

    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
  }
}
.btn_group {
  display: flex;
  border-top: 1px solid var(--neutral-color-eeeeee, #eee);
  height: 80px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--light-333333, var(--neutral-color-333333, #333));
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}
.btn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--light-333333, var(--neutral-color-333333, #2196f3));
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
}
</style>