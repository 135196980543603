<template>
  <div class="home_warp">
    <div class="chartsDialog"
         ref="studyInfoRef"
         v-if="showStudyInfo">
      <div class="charts_content_wrap">
        <div class="icon"
             @click="toggleStudyInfo(0)">
          <img src="@/assets/personalCenter/close.png"
               alt=""
               title="关闭">
        </div>
        <!-- <div>
          <div class="title">成长值</div>
          <Chart1 myId="diologChart1" />
          <div class="title">得分率</div>
          <Chart2 myId="diologChart2" />
        </div> -->
        <div style="margin-top:83rem;width:1134rem;">
          <StudyInfo isDialogId="001"
                     style="max-height: 84vh;overflow: auto;" />
        </div>
      </div>
    </div>
    <div class="top_bg"
         v-show="showBgFlag">
      <!-- <div></div>
      <div style="display:flex;align-items:center;">
        <div class="bg_text1">vip</div>
        <div class="bg_text">高考全科AI学情检测VIP仅需￥3999/年（畅享6科无限制精准提分训练）</div>
        <div class="bg_btn"
             @click="toPage('/buyVip')">
          <div class="text">限时抢购 ></div>
        </div>
      </div> -->
      <!-- <div>
        <img src="@/assets/personalCenter/横幅广告.png"
             alt=""
             style="width: 1920rem;"
             @click="toBuy"
             title="">
      </div> -->
      <div class="bg_icon"> <img src="@/assets/personalCenter/closed.png"
             alt=""
             @click="closeBg"
             title="关闭"></div>
    </div>
    <div class="left_controller">

      <div class="item infoicion"
           style="position:fixed;top:15rem;"
           @click="toggleStudyInfo(1)"
           title="成长值与得分率">
        <img src="@/assets/home/icon-成长值.png"
             alt="">
      </div>
      <div class="item"
           v-if="userInfo.user_type_id == 14"
           @click="toPage('/free')">
        <img src="@/assets/home/免费专区1.png"
             v-if="path != '/free'"
             alt="">
        <img src="@/assets/home/免费专区.png"
             v-else
             alt="">
        <span :class="{'active':path == '/free'}">免费专区</span>
      </div>
      <div class="item"
           @click="toPage('/index')">
        <img src="@/assets/home/首页.png"
             v-if="path != '/index'"
             alt="">
        <img src="@/assets/home/首页_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/index'}">首页</span>
      </div>
      <div class="item"
           @click="toPage('/errorbasis')">
        <img src="@/assets/home/错题本.png"
             v-if="path != '/errorbasis'"
             alt="">
        <img src="@/assets/home/错题本_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/errorbasis'}">
          错题本
        </span>
      </div>
      <div class="item"
           v-if="noExpreience"
           @click="showPop">
        <img src="@/assets/home/数据中心.png"
             alt="">
        <div class="mask"
             v-show="showModals"></div>
        <el-popover placement="right"
                    title=""
                    v-model="visible"
                    trigger="manual"
                    @show="showModals=true"
                    @after-leave="showModals=false"
                    popper-class="ccsd"
                    content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
          <div style="display: flex;
            align-items: center;
    flex-direction: column;">
            <div class="title">导出</div>
            <div class="box"
                 @click="exportReport">
              <el-button size="mini"
                         :disabled="btnDisabled"
                         icon="el-icon-my-userfocus"></el-button>
              <span class="btntext">学科数据</span>

            </div>
            <div class="box"
                 @click="exportExplain(1)">
              <el-button size="mini"
                         :disabled="btnDisabled"
                         icon="el-icon-my-books"></el-button>
              <span class="btntext">定制教辅</span>
            </div>
            <!-- <div class="box"
                 @click="exportExplain(2)">
              <el-button size="mini"
                         :disabled="btnDisabled"
                         icon="el-icon-my-pencilline"></el-button>
              <span class="btntext">定制习题</span>
            </div> -->
          </div>

          <span slot="reference">数据中心</span>
        </el-popover>
      </div>
      <div class="item"
           @click="toPage('/analyze_report_wrap')">
        <img src="@/assets/home/分析报告.png"
             v-if="path != '/analyze_report_wrap'"
             alt="">
        <img src="@/assets/home/分析报告_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/analyze_report_wrap'}">
          分析报告
        </span>
      </div>
      <div class="item"
           @click="toPage('/course')"
           v-if="userInfo.show_video!=0">
        <img src="@/assets/home/优质教程.png"
             v-if="path != '/course'&& path!='/lecture_free'"
             alt="">
        <img src="@/assets/home/优质教程_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/course'}">
          优质课程
        </span>
      </div>
      <div class="item"
           @click="toPage('/personalCenter')">
        <span class="free-use"
              v-if="userInfo.user_type_id == 14">免费试用</span>
        <img src="@/assets/home/个人中心.png"
             v-if="path != '/info'"
             alt="">
        <img src="@/assets/home/个人中心_active.png"
             v-else
             alt="">
        <span :class="{'active':path == '/info'}">
          个人中心
        </span>
      </div>
    </div>
    <div class="right_view">
      <router-view></router-view>
    </div>
    <FormDialog ref="FormRef"></FormDialog>
    <ScoreDialog ref="ScoreRef"></ScoreDialog>
    <VideoDialog ref="VideoRef"></VideoDialog>
  </div>
</template>

<script>
import StudyInfo from "./personalCenter/components/studyInfo.vue"
import { getTeacherReport } from '@/api/home.js'
import FormDialog from "@/views/index/components/formDialog.vue"
import ScoreDialog from "@/views/index/components/scoreDialog.vue"
import VideoDialog from "@/views/index/components/videoDialog.vue"
// import Chart1 from './personalCenter/components/charts/mychart1.vue'
// import Chart2 from './personalCenter/components/charts/myChart2.vue'
import { getOneDayTime } from '@/utils/http.js'
import { getVipInfo } from '@/api/vip.js'
export default {
  data () {
    return {
      path: '',
      showStudyInfo: false,
      userInfo: {},
      showModals: false,
      visible: false,
      btnDisabled: false,
      showBgFlag: false,
      isFree: true,
      noExpreience: true,
    }
  },

  watch: {
    $route: {
      handler: function (val, oldVal) {

        this.path = val.path

      },
      // 深度观察监听
      deep: true
    },
  },

  components: {
    StudyInfo,
    FormDialog,
    ScoreDialog,
    VideoDialog,
    //  Chart1, Chart2
  },
  async created () {


  },
  mounted () {

    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    this.path = this.$route.path
    console.log('this.this.path', this.path)
    // 监听浏览器缓存是否修改，主要是userInfo，修改了则页面刷新，解决重新登录后vuex缓存没变
    window.addEventListener('storage', this.watchStorge)
    // getOneDayTime()

    if (this.userInfo.user_type_id == 14) {
      this.showBgFlag = true
    }
    if (this.userInfo.user_type_id == 16) {
      this.noExpreience = false
    }
    this.loadVipInfo()
    this.$EventBus.on('vipIsExpiredEvent', data => {
      this.isFree = data
    })
    if (this.userInfo.is_show_introduce == 1) {
      this.$refs.VideoRef.dialogVisible = true
    }

    setTimeout(() => {
      if (window.localStorage.getItem('score_dialog')) {
        this.$refs.ScoreRef.dialogVisible = JSON.parse(window.localStorage.getItem('score_dialog'));
      } else if (JSON.parse(window.localStorage.getItem('totalScore')) > 0) {
        this.$refs.ScoreRef.dialogVisible = false
      }
      else {
        this.$refs.ScoreRef.dialogVisible = true
      }
    }, 3000)


  },
  destroyed () {
    window.removeEventListener('storage', this.watchStorge)
  },
  methods: {
    closeBg () {
      this.showBgFlag = false
    },
    showPop () {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
      if (userInfo.user_type_id == 14 && !userInfo.vip_end_time) {
        this.$message.info('请先开通vip')
        this.$router.push('/buyVip')
        return
      }
      this.path = ''
      this.visible = !this.visible
    },
    toBuy () {
      this.$router.push('/buyVip')
    },

    base64ToBlob (base64) {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: 'application/octet-stream' });
    },
    async exportReport () {
      this.btnDisabled = true
      try {

        await getTeacherReport().then(res => {
          const a = document.createElement("a")
          const blob = this.base64ToBlob(res.data.base64Content)
          // console.log('blob', blob)
          if (blob.size > 0) {
            this.btnDisabled = false
          }
          a.href = window.URL.createObjectURL(blob)
          a.download = `分析报告.docx`
          a.click()
        })


        // const data = await getTeacherReport()
        // if (data.size > 0) {
        //   this.btnDisabled = false
        // }
        // let blob = new Blob([data], { type: 'application/msword;charset=utf-8' });
        // saveAs(blob, `分析报告.docx`)
      } catch {
        this.$message.error('未有相关做卷信息');
      }

    },
    async exportExplain (has_explain) {
      this.$refs.FormRef.dialogVisible = true
      this.$refs.FormRef.has_explain = has_explain
      this.$refs.FormRef.checkedCitiesOpt = []
      this.$refs.FormRef.checkAll = false
    },
    toPage (path) {
      this.path = path
      this.$router.push(path)
    },
    toggleStudyInfo (bool) {
      // this.$refs.studyInfoRef.style.display
      if (bool) {
        this.showStudyInfo = !this.showStudyInfo
      } else {

        this.showStudyInfo = bool
      }
    },
    watchStorge (event) {
      if (event.key == 'userInfo') {
        this.$store.commit('CLEAR_SUBJECT')
        // this.$router.go(0)
      }
    },
    loadVipInfo () {
      let that = this
      getVipInfo().then(res => {
        let vipInfo = res.data
        if (vipInfo && vipInfo.vip_end_time != null && vipInfo.vip_end_time.length > 0) {
          let curDate = new Date()
          let vipExpired = new Date(vipInfo.vip_end_time)
          that.isFree = curDate > vipExpired
        }
      })
    },
  }
}
</script>

<style lang="scss" >
.el-popover.ccsd {
  min-width: 92rem !important;
  width: 92rem !important;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 8px 8px;
  padding: 1rem;
  font-size: 16rem;
  left: 105rem !important;
  opacity: 1;
  .el-popover__title {
    width: 32rem;
    height: 24rem;
    font-size: 16rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24rem;
    margin-left: 30rem;
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  .title {
    width: 32rem;
    height: 24rem;
    font-size: 16rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
    color: #666666;
    line-height: 24rem;
    -webkit-background-clip: text;
    margin-bottom: 8rem;
    padding: 16rem 16rem 0rem 16rem;
  }
  .box {
    width: 80rem;
    height: 80rem;
    padding-top: 8rem;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-bottom: 5rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    .btntext {
      color: #666666;
      padding: 8rem !important;
    }
    .el-button {
      height: 40rem;
      border-radius: 8px 8px 8px 8px;
      padding: 0px !important;
      width: 40rem;
      opacity: 1;
      // background: #fafafa;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .el-button:hover {
      background-color: #ffffff;
    }
    .el-button:hover,
    .el-button:focus {
      // background: #fafafa;
      border-color: #eaecf0;
    }
  }
  .box:hover {
    background-color: #fafafa;
  }
  .el-icon-my-userfocus {
    background: url("../assets/home/UserFocus.png") center no-repeat;
    background-size: cover;
    width: 24rem;
    height: 24rem;
  }
  .el-icon-my-userfocus:before {
    content: "替";
    font-size: 16px;
    visibility: hidden;
  }
  .el-icon-my-books {
    background: url("../assets/home/Books.png") center no-repeat;
    background-size: cover;
    width: 24rem;
    height: 24rem;
  }
  .el-icon-my-books:before {
    content: "替";
    font-size: 16px;
    visibility: hidden;
  }
  .el-icon-my-pencilline {
    background: url("../assets/home/PencilLine.png") center no-repeat;
    background-size: cover;
    width: 24rem;
    height: 24rem;
  }
  .el-icon-my-pencilline:before {
    content: "替";
    font-size: 16px;
    visibility: hidden;
  }
}
.home_warp {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
  .top_bg {
    display: flex;
    position: fixed;
    height: 48rem;
    // padding: 0rem 16rem;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    // width: calc(100vw - 32rem);
    z-index: 2000;
    // background: linear-gradient(
    //   90deg,
    //   #eb5a49 0%,
    //   #fca168 26.43%,
    //   #f3b063 30.37%,
    //   #ec5e49 43%,
    //   #fd9b55 58.5%,
    //   #ea4e38 100%
    // );
    .bg_icon {
      display: flex;
      width: 24rem;
      height: 24rem;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: var(--radius-radius_max, 100px);
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      right: 15rem;
    }
    .bg_text1 {
      font-family: "Alimama Agile VF";
      font-size: 65rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      opacity: 0.2;
      background: linear-gradient(294deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .bg_text {
      color: #fff;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
      font-family: "Alimama FangYuanTi VF";
      font-size: 24rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .bg_btn {
      display: flex;
      width: 124rem;
      height: 36rem;
      justify-content: center;
      align-items: center;
      border-radius: var(--radius-radius_max, 100px);
      background: linear-gradient(180deg, #fef7e8 0%, #fecc65 100%);
      box-shadow: 2px 2px 4px 0px rgba(234, 92, 13, 0.8);
      .text {
        color: #fc4040;
        text-shadow: 0px 1px 1px #fff;
        font-family: "Alimama FangYuanTi VF";
        font-size: 20rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  .left_controller {
    z-index: 1999;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5.21vw;
    min-width: 100rem;
    // height: 100vh;
    background: #ffffff;
    box-shadow: 0rem 2rem 8rem 2rem rgba(0, 0, 0, 0.1);
    height: calc(100vh - 48rem);
    padding-top: 48rem;
    #my {
      width: 100px;
      height: 100px;
      background-color: #2196f3;
      font-size: 12px;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30rem;
      font-weight: 500;
      color: #888888;
      font-size: 20rem;
      cursor: pointer;
      img {
        width: 44rem;
        height: 44rem;
      }
      .mask {
        background-color: #000;
        opacity: 0.3;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .free-use {
        display: flex;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        margin-bottom: 4px;
        border-radius: 16px 16px 16px 0px;
        border: 1px solid #fff;
        background: var(--Error-color_06, #f44336);
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
  .right_view {
    flex: 1;
    width: calc(100vw - 100rem);
    // height: 100vh;
    height: calc(100vh - 48rem);
    overflow-y: auto;
    background: #f6f6f6;
    margin-top: 48rem;
  }
}
.active {
  color: #2196f3 !important;
}
.chartsDialog {
  .title {
    font-size: 26rem;
  }
  .charts_content_wrap {
    position: relative;
  }
  .icon {
    position: absolute;
    top: -40rem;
    right: -4rem;
    width: 50rem;
    height: 50rem;
    cursor: pointer;
    img {
      width: 130%;
      height: 130%;
    }
  }
  position: fixed;
  background: #fff;
  box-shadow: 6px 0px 61px 1px rgb(117 195 255 / 60%);
  z-index: 99;
  left: 132rem;
  top: 80rem;
  padding: 20rem 0rem 0rem;
  border-radius: 19rem;
}
.guideDialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>